import React from "react";
import { BarChart2 } from "lucide-react";

const ChartContainer = ({
  title,
  children,
  icon: Icon = BarChart2,
  height = "h-64",
}) => (
  <div className="bg-white rounded-lg shadow-lg p-6">
    <div className="flex items-center mb-4">
      <Icon
        className="w-6 h-6 text-blue-600 mr-2"
        aria-label={`${title} icon`}
      />
      <h2 className="text-xl font-bold text-gray-800">{title}</h2>
    </div>
    <div className={`${height}`}>{children}</div>
  </div>
);

export default ChartContainer;
