export const talentPoolData = [
  { name: "Software Engineers", value: 300 },
  { name: "Data Scientists", value: 150 },
  { name: "Product Managers", value: 100 },
  { name: "Designers", value: 75 },
  { name: "Sales", value: 200 },
];

export const skillGapData = [
  { name: "AI/ML", current: 70, required: 90 },
  { name: "Cloud Computing", current: 80, required: 85 },
  { name: "Data Analysis", current: 65, required: 80 },
  { name: "UX Design", current: 75, required: 80 },
  { name: "Cybersecurity", current: 60, required: 85 },
];

export const hiringPipelineData = [
  { name: "Applied", value: 500 },
  { name: "Screening", value: 300 },
  { name: "Interview", value: 150 },
  { name: "Offer", value: 50 },
  { name: "Hired", value: 30 },
];

export const retentionPredictorData = [
  { name: "High Risk", value: 15 },
  { name: "Medium Risk", value: 30 },
  { name: "Low Risk", value: 55 },
];

export const diversityData = [
  { name: "Gender", current: 0.45, target: 0.5 },
  { name: "Ethnicity", current: 0.35, target: 0.4 },
  { name: "Age", current: 0.6, target: 0.7 },
  { name: "Disability", current: 0.1, target: 0.15 },
];

export const engagementData = [
  { name: "Jan", score: 7.2 },
  { name: "Feb", score: 7.4 },
  { name: "Mar", score: 7.3 },
  { name: "Apr", score: 7.6 },
  { name: "May", score: 7.8 },
  { name: "Jun", score: 8.0 },
];

export const talentRecommendations = [
  { name: "John Doe", position: "Senior Software Engineer", matchScore: "95%" },
  { name: "Jane Smith", position: "Data Scientist", matchScore: "92%" },
  { name: "Mike Johnson", position: "Product Manager", matchScore: "88%" },
  { name: "Emily Brown", position: "UX Designer", matchScore: "91%" },
  { name: "Chris Lee", position: "Sales Manager", matchScore: "89%" },
];

export const learningRecommendations = [
  "Advanced Machine Learning Course",
  "Leadership Skills Workshop",
  "Cloud Architecture Certification",
  "Agile Project Management Training",
  "Data Visualization Masterclass",
];

export const performanceData = [
  { category: "Exceeds", value: 20 },
  { category: "Meets", value: 50 },
  { category: "Needs Improvement", value: 25 },
  { category: "Unsatisfactory", value: 5 },
];

export const successionPlanningData = [
  { name: "Ready Now", value: 15 },
  { name: "Ready in 1-2 Years", value: 30 },
  { name: "Ready in 3-5 Years", value: 40 },
  { name: "Not in Pipeline", value: 15 },
];
