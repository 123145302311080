import React, { useState } from "react";
import {
  Users,
  Star,
  ChevronRight,
  Brain,
  Zap,
  Target,
  TrendingUp,
} from "lucide-react";

const TalentRecommendations = ({ recommendations }) => {
  const [selectedTalent, setSelectedTalent] = useState(null);

  const aiFeatures = [
    {
      icon: <Brain className="w-6 h-6 text-indigo-600" />,
      title: "Cognitive Pattern Matching",
      description:
        "AI analyzes work patterns and problem-solving approaches to find candidates who think similarly to top performers.",
    },
    {
      icon: <Zap className="w-6 h-6 text-indigo-600" />,
      title: "Predictive Performance Modeling",
      description:
        "Machine learning algorithms predict future performance based on historical data and success patterns.",
    },
    {
      icon: <Target className="w-6 h-6 text-indigo-600" />,
      title: "Adaptive Skill Mapping",
      description:
        "AI continuously updates skill requirements based on industry trends and company-specific needs.",
    },
    {
      icon: <TrendingUp className="w-6 h-6 text-indigo-600" />,
      title: "Dynamic Team Composition",
      description:
        "Recommendations for optimal team structures based on project requirements and individual strengths.",
    },
  ];

  return (
    <div className="bg-white rounded-lg shadow-xl p-6">
      <div className="flex items-center mb-6">
        <Users className="w-8 h-8 text-indigo-600 mr-3" />
        <h2 className="text-2xl font-bold text-gray-800">
          AI-Powered Talent Recommendations
        </h2>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-8">
        {aiFeatures.map((feature, index) => (
          <div
            key={index}
            className="flex items-start p-4 bg-indigo-50 rounded-lg"
          >
            {feature.icon}
            <div className="ml-4">
              <h3 className="text-lg font-semibold text-indigo-800">
                {feature.title}
              </h3>
              <p className="text-sm text-gray-600">{feature.description}</p>
            </div>
          </div>
        ))}
      </div>

      <div className="overflow-x-auto">
        <table className="min-w-full bg-white">
          <thead className="bg-indigo-50">
            <tr>
              <th className="py-3 px-4 text-left text-xs font-medium text-indigo-800 uppercase tracking-wider">
                Name
              </th>
              <th className="py-3 px-4 text-left text-xs font-medium text-indigo-800 uppercase tracking-wider">
                Position
              </th>
              <th className="py-3 px-4 text-left text-xs font-medium text-indigo-800 uppercase tracking-wider">
                AI Match Score
              </th>
              <th className="py-3 px-4 text-left text-xs font-medium text-indigo-800 uppercase tracking-wider">
                Action
              </th>
            </tr>
          </thead>
          <tbody className="divide-y divide-indigo-100">
            {recommendations.map((talent, index) => (
              <tr
                key={index}
                className="hover:bg-indigo-50 transition-colors duration-200"
              >
                <td className="py-4 px-4 whitespace-nowrap">
                  <div className="flex items-center">
                    <div className="flex-shrink-0 h-10 w-10">
                      <img
                        className="h-10 w-10 rounded-full"
                        src={`https://ui-avatars.com/api/?name=${talent.name}&background=random`}
                        alt=""
                      />
                    </div>
                    <div className="ml-4">
                      <div className="text-sm font-medium text-gray-900">
                        {talent.name}
                      </div>
                    </div>
                  </div>
                </td>
                <td className="py-4 px-4 whitespace-nowrap">
                  <div className="text-sm text-gray-900">{talent.position}</div>
                </td>
                <td className="py-4 px-4 whitespace-nowrap">
                  <div className="flex items-center">
                    <Star className="w-5 h-5 text-yellow-400 mr-1" />
                    <span className="text-sm font-medium text-gray-900">
                      {talent.matchScore}
                    </span>
                  </div>
                </td>
                <td className="py-4 px-4 whitespace-nowrap text-sm font-medium">
                  <button
                    onClick={() => setSelectedTalent(talent)}
                    className="bg-indigo-600 hover:bg-indigo-700 text-white font-bold py-2 px-4 rounded-full transition-colors duration-200 flex items-center"
                  >
                    View Profile
                    <ChevronRight className="w-4 h-4 ml-1" />
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {selectedTalent && (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full z-50">
          <div className="relative top-20 mx-auto p-5 border w-96 shadow-lg rounded-md bg-white">
            <div className="mt-3 text-center">
              <h3 className="text-lg leading-6 font-medium text-gray-900">
                {selectedTalent.name}
              </h3>
              <div className="mt-2 px-7 py-3">
                <p className="text-sm text-gray-500">
                  Position: {selectedTalent.position}
                </p>
                <p className="text-sm text-gray-500">
                  AI Match Score: {selectedTalent.matchScore}
                </p>
                <p className="text-sm text-gray-500 mt-4">
                  Our AI has identified this candidate as an excellent match
                  based on their cognitive patterns, predicted performance, and
                  alignment with your team's dynamics. Their skills and
                  problem-solving approach closely resemble those of your top
                  performers in similar roles.
                </p>
              </div>
              <div className="items-center px-4 py-3">
                <button
                  onClick={() => setSelectedTalent(null)}
                  className="px-4 py-2 bg-indigo-600 text-white text-base font-medium rounded-md w-full shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500"
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default TalentRecommendations;
