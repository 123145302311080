import React, { useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { 
  Brain, 
  Network, 
  Target, 
  Compass, 
  Layers,
  Users,
  ArrowRight,
  Play,
  BarChart2
} from 'lucide-react';

// Define styles
const styles = `
  @keyframes fadeIn {
    from { 
      opacity: 0; 
      transform: translateY(20px); 
    }
    to { 
      opacity: 1; 
      transform: translateY(0); 
    }
  }

  @keyframes float {
    0% { transform: translateY(0px); }
    50% { transform: translateY(-10px); }
    100% { transform: translateY(0px); }
  }

  @keyframes gradientShift {
    0% { background-position: 0% 50%; }
    50% { background-position: 100% 50%; }
    100% { background-position: 0% 50%; }
  }

  .animate-fadeIn {
    opacity: 0;
    animation: fadeIn 1s ease-out forwards;
  }

  .animate-float {
    animation: float 3s ease-in-out infinite;
  }

  .animate-gradient {
    background-size: 200% 200%;
    animation: gradientShift 3s ease infinite;
  }

  .card-hover {
    transition: all 0.3s ease;
  }

  .card-hover:hover {
    transform: translateY(-5px);
    box-shadow: 0 10px 30px -10px rgba(0, 255, 255, 0.2);
  }
    @keyframes shimmer {
    0% { background-position: -200% center; }
    100% { background-position: 200% center; }
  }

  .animate-shimmer {
    background: linear-gradient(
      90deg,
      rgba(251, 191, 36, 0) 0%,
      rgba(251, 191, 36, 0.2) 50%,
      rgba(251, 191, 36, 0) 100%
    );
    background-size: 200% 100%;
    animation: shimmer 3s infinite;
  }

  .logo-shadow {
    filter: drop-shadow(0 0 10px rgba(251, 191, 36, 0.3));
  }
`;

// Particle Background Component
const ParticleBackground = () => {
  const canvasRef = useRef(null);

  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    let animationFrameId;

    // Set canvas size
    const resizeCanvas = () => {
      canvas.width = window.innerWidth;
      canvas.height = window.innerHeight;
    };

    window.addEventListener('resize', resizeCanvas);
    resizeCanvas();

    // Particle class
    class Particle {
      constructor() {
        this.x = Math.random() * canvas.width;
        this.y = Math.random() * canvas.height;
        this.vx = Math.random() * 0.2 - 0.1;
        this.vy = Math.random() * 0.2 - 0.1;
        this.radius = Math.random() * 2;
        this.originalRadius = this.radius;
        this.targetRadius = this.radius;
      }

      draw() {
        ctx.beginPath();
        ctx.arc(this.x, this.y, this.radius, 0, Math.PI * 2);
        ctx.fillStyle = 'rgba(255, 255, 255, 0.5)';
        ctx.fill();
      }

      update() {
        this.x += this.vx;
        this.y += this.vy;

        // Bounce off edges
        if (this.x < 0 || this.x > canvas.width) this.vx = -this.vx;
        if (this.y < 0 || this.y > canvas.height) this.vy = -this.vy;

        // Smooth radius transition
        this.radius += (this.targetRadius - this.radius) * 0.1;
      }

      pulse() {
        this.targetRadius = this.originalRadius * 2;
        setTimeout(() => {
          this.targetRadius = this.originalRadius;
        }, 200);
      }
    }

    // Create particles
    const particles = Array(50).fill().map(() => new Particle());

    // Mouse interaction
    let mouse = { x: null, y: null };
    const handleMouseMove = (e) => {
      mouse.x = e.clientX;
      mouse.y = e.clientY;

      // Pulse particles near mouse
      particles.forEach(particle => {
        const dx = mouse.x - particle.x;
        const dy = mouse.y - particle.y;
        const distance = Math.sqrt(dx * dx + dy * dy);
        if (distance < 50) particle.pulse();
      });
    };

    canvas.addEventListener('mousemove', handleMouseMove);

    // Animation loop
    const animate = () => {
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      particles.forEach(particle => {
        particle.update();
        particle.draw();
      });
      animationFrameId = requestAnimationFrame(animate);
    };

    animate();

    // Cleanup
    return () => {
      window.removeEventListener('resize', resizeCanvas);
      canvas.removeEventListener('mousemove', handleMouseMove);
      cancelAnimationFrame(animationFrameId);
    };
  }, []);

  return <canvas ref={canvasRef} className="absolute inset-0 -z-10" />;
};

// Main Landing Page Component
const LandingPage = () => {
  const solutions = [
    {
      icon: Brain,
      title: "Semantic AI Matching",
      description: "Transform hiring with AI that understands complete professional narratives, not just keyword matches on resumes.",
      badge: "Core Technology"
    },
    {
      icon: BarChart2,
      title: "Smart Compensation",
      description: "Pay right, stay competitive. Real-time salary insights and equity analysis that keeps your best talent happy.",
      badge: "Strategic HR"
    },
    {
      icon: Network,
      title: "Skills Evolution Mapping",
      description: "Visualize and forecast skill transitions across roles, identifying unexpected talent matches and career possibilities.",
      badge: "Talent Insights"
    },
    {
      icon: Target,
      title: "Precision Recruiting",
      description: "Cut hiring time in half. Target and attract the right candidates faster with data-driven campaigns.",
      badge: "Recruitment"
    },
    {
      icon: Compass,
      title: "Growth Navigator",
      description: "Show them where to grow. AI-powered career paths that turn potential into performance.",
      badge: "Development"
    },
    {
      icon: Users,
      title: "Team Composition Analytics",
      description: "Optimize team structures through analysis of skill distribution, cognitive diversity, and performance patterns.",
      badge: "Team Science"
    }
  ];

  const stats = [
    { value: "93%", label: "Improvement in Quality of Hire" },
    { value: "75%", label: "Faster Time to Hire" },
    { value: "2.5x", label: "Higher Employee Retention" }
  ];

  return (
    <div className="min-h-screen bg-gradient-to-b from-slate-900 via-slate-800 to-slate-900 relative overflow-hidden">
      <nav className="fixed w-full bg-opacity-95 backdrop-blur-lg bg-slate-900/95 z-50">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex justify-between h-16 items-center">
            {/* Logo Section */}
            <div className="flex items-center space-x-3">
              {/* Logo Square */}
              <div className="w-10 h-10 rounded-lg bg-orange-500 flex items-center justify-center shadow-lg shadow-orange-500/20">
                <span className="text-2xl font-bold text-white">S</span>
              </div>
              
              {/* Brand Name */}
              <div className="flex flex-col">
                <span className="text-xl font-bold text-white">
                  SpectrumHR.ai
                </span>
                <span className="text-xs text-orange-500/90 font-medium tracking-wider">
                  TALENT INTELLIGENCE
                </span>
              </div>
            </div>

            {/* Navigation Links */}
            <div className="hidden md:flex items-center space-x-8">
              <a href="#solutions" className="text-gray-300 hover:text-white transition-colors duration-300">
                Solutions
              </a>
              <a href="#impact" className="text-gray-300 hover:text-white transition-colors duration-300">
                Impact
              </a>
              <button className="bg-gradient-to-r from-orange-500 to-orange-600 text-white px-6 py-2 rounded-full hover:shadow-lg hover:shadow-orange-500/20 transition-all duration-300 hover:-translate-y-0.5">
                Contact Us
              </button>
            </div>

            {/* Mobile Menu */}
            <div className="md:hidden">
              <button className="bg-gradient-to-r from-orange-500 to-orange-600 text-white px-4 py-1.5 text-sm rounded-full hover:shadow-lg hover:shadow-orange-500/20 transition-all duration-300">
                Contact Us
              </button>
            </div>
          </div>
        </div>
      </nav>

      {/* Add a subtle gradient line under the header */}
      <div className="fixed top-16 left-0 w-full h-px bg-gradient-to-r from-transparent via-orange-500/20 to-transparent z-50"></div>

      <main>
        {/* Hero Section */}
        <section className="pt-32 pb-20 px-4 relative">
          <div className="max-w-7xl mx-auto text-center">
            <h1 className="text-5xl md:text-7xl font-bold mb-6 animate-fadeIn">
              <span className="bg-gradient-to-r from-cyan-400 via-blue-500 to-purple-500 text-transparent bg-clip-text animate-gradient">
                Redefining Talent Discovery
              </span>
            </h1>
            <p className="text-xl text-gray-300 max-w-3xl mx-auto mb-12 animate-fadeIn" style={{animationDelay: '0.5s'}}>
              Experience the power of LLMs and contextual AI in talent management. Move beyond traditional keyword matching to unlock the true potential of your workforce.
            </p>
            <div className="flex justify-center gap-6 animate-fadeIn" style={{animationDelay: '0.8s'}}>
              <Link to="/dashboard" className="group bg-gradient-to-r from-cyan-500 to-blue-500 text-white px-8 py-4 rounded-full hover:shadow-lg hover:shadow-cyan-500/25 transition-all duration-300 hover:scale-105 flex items-center">
                Watch Demo
                <Play className="ml-2 w-4 h-4 group-hover:translate-x-1 transition-transform" />
              </Link>
            </div>
          </div>
        </section>

        {/* Solutions Grid */}
        <section id="solutions" className="py-20 px-4">
          <div className="max-w-7xl mx-auto">
            <h2 className="text-3xl md:text-4xl font-bold text-center mb-16 text-white">
              Intelligent Solutions for Modern Workforce
            </h2>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
              {solutions.map((solution, index) => (
                <div
                  key={index}
                  className="card-hover bg-slate-800/50 backdrop-blur-sm rounded-xl p-8 border border-cyan-500/20 group animate-fadeIn"
                  style={{animationDelay: `${index * 0.2}s`}}
                >
                  <div className="flex items-center mb-6">
                    <solution.icon className="w-12 h-12 text-cyan-400 group-hover:scale-110 transition-transform duration-300" />
                    <span className="ml-2 text-xs font-semibold text-cyan-400 bg-cyan-400/10 px-3 py-1 rounded-full">
                      {solution.badge}
                    </span>
                  </div>
                  <h3 className="text-xl font-semibold text-white mb-4">{solution.title}</h3>
                  <p className="text-gray-300">{solution.description}</p>
                </div>
              ))}
            </div>
          </div>
        </section>

        {/* Stats Section */}
        <section id="impact" className="py-20 px-4 bg-gradient-to-r from-cyan-500/10 via-blue-500/10 to-purple-500/10">
          <div className="max-w-7xl mx-auto text-center">
            <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
              {stats.map((stat, index) => (
                <div 
                  key={index}
                  className="p-6 hover:scale-105 transition-transform duration-300"
                >
                  <div className="text-5xl font-bold text-white mb-3">{stat.value}</div>
                  <p className="text-gray-300 text-lg">{stat.label}</p>
                </div>
              ))}
            </div>
          </div>
        </section>

        {/* CTA Section */}
        <section className="py-20 px-4">
          <div className="max-w-4xl mx-auto text-center">
            <h2 className="text-3xl font-bold text-white mb-6">
              Ready to Transform Your Talent Strategy?
            </h2>
            <p className="text-gray-300 mb-8">
              Join forward-thinking organizations already leveraging the power of contextual AI.
            </p>
            <Link className="bg-gradient-to-r from-cyan-500 to-blue-500 text-white px-8 py-3 rounded-full hover:shadow-lg hover:shadow-cyan-500/25 transition-all duration-300 hover:scale-105">
              Contact Sales
            </Link>
          </div>
        </section>
      </main>

      {/* Footer */}
      <footer className="bg-slate-900/80 backdrop-blur-sm text-gray-400 py-12 px-4 border-t border-white/5">
        <div className="max-w-7xl mx-auto">
          <div className="text-center">
            <p>&copy; 2024 SpectrumHR.ai. All rights reserved.</p>
            <p className="mt-2">Empowering organizations with intelligent talent solutions.</p>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default LandingPage;
