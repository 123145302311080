import React from "react";

const LearningRecommendations = ({ recommendations }) => (
  <div className="bg-white rounded-lg shadow-md p-6">
    <h2 className="text-xl font-semibold mb-4">Learning & Development</h2>
    <ul className="list-disc pl-5">
      {recommendations.map((recommendation, index) => (
        <li key={index}>{recommendation}</li>
      ))}
    </ul>
  </div>
);

export default LearningRecommendations;
