import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Home, Users, Briefcase, Zap, TrendingUp, AlertTriangle, Activity } from "react-feather";
import ChartContainer from "./components/ChartContainer";
import TalentRecommendations from "./components/TalentRecommendations";
import LearningRecommendations from "./components/LearningRecommendations";
import {
  talentPoolData,
  skillGapData,
  hiringPipelineData,
  retentionPredictorData,
  diversityData,
  engagementData,
  talentRecommendations,
  learningRecommendations,
} from "./data/mockData";
import {
  PieChart, Pie, Cell, ResponsiveContainer, RadarChart, PolarGrid, PolarAngleAxis, PolarRadiusAxis, Radar, BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, LineChart, Line
} from "recharts";

const Dashboard = () => {
  const [activeTab, setActiveTab] = useState("overview");

  const renderTabContent = () => {
    switch (activeTab) {
      case "overview":
        return renderOverviewTab();
      case "talent":
        return renderTalentTab();
      case "learning":
        return renderLearningTab();
      default:
        return renderOverviewTab();
    }
  };

  const renderOverviewTab = () => (
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
      <ChartContainer title="Talent Pool Overview" icon={Users}>
        <ResponsiveContainer width="100%" height={300}>
          <PieChart>
            <Pie
              data={talentPoolData}
              dataKey="value"
              nameKey="name"
              cx="50%"
              cy="50%"
              outerRadius={80}
              fill="#8884d8"
              label
            >
              {talentPoolData.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#8884D8'][index % 5]} />
              ))}
            </Pie>
            <Tooltip />
            <Legend />
          </PieChart>
        </ResponsiveContainer>
      </ChartContainer>

      <ChartContainer title="Skill Gap Analysis" icon={Activity}>
        <ResponsiveContainer width="100%" height={300}>
          <RadarChart data={skillGapData}>
            <PolarGrid />
            <PolarAngleAxis dataKey="name" />
            <PolarRadiusAxis angle={30} domain={[0, 100]} />
            <Radar name="Current" dataKey="current" stroke="#8884d8" fill="#8884d8" fillOpacity={0.6} />
            <Radar name="Required" dataKey="required" stroke="#82ca9d" fill="#82ca9d" fillOpacity={0.6} />
            <Legend />
          </RadarChart>
        </ResponsiveContainer>
      </ChartContainer>

      <ChartContainer title="Hiring Pipeline" icon={TrendingUp}>
        <ResponsiveContainer width="100%" height={300}>
          <BarChart data={hiringPipelineData}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" />
            <YAxis />
            <Tooltip />
            <Legend />
            <Bar dataKey="value" fill="#8884d8" />
          </BarChart>
        </ResponsiveContainer>
      </ChartContainer>

      <ChartContainer title="Employee Retention Predictor" icon={AlertTriangle}>
        <ResponsiveContainer width="100%" height={300}>
          <PieChart>
            <Pie
              data={retentionPredictorData}
              dataKey="value"
              nameKey="name"
              cx="50%"
              cy="50%"
              outerRadius={80}
              fill="#8884d8"
              label
            >
              {retentionPredictorData.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={['#FF8042', '#FFBB28', '#00C49F'][index % 3]} />
              ))}
            </Pie>
            <Tooltip />
            <Legend />
          </PieChart>
        </ResponsiveContainer>
      </ChartContainer>

      <ChartContainer title="Diversity and Inclusion Metrics" icon={Users}>
        <ResponsiveContainer width="100%" height={300}>
          <BarChart data={diversityData}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" />
            <YAxis />
            <Tooltip />
            <Legend />
            <Bar dataKey="current" fill="#8884d8" name="Current" />
            <Bar dataKey="target" fill="#82ca9d" name="Target" />
          </BarChart>
        </ResponsiveContainer>
      </ChartContainer>

      <ChartContainer title="Employee Engagement Trends" icon={TrendingUp}>
        <ResponsiveContainer width="100%" height={300}>
          <LineChart data={engagementData}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" />
            <YAxis />
            <Tooltip />
            <Legend />
            <Line type="monotone" dataKey="score" stroke="#8884d8" activeDot={{ r: 8 }} />
          </LineChart>
        </ResponsiveContainer>
      </ChartContainer>
    </div>
  );

  const renderTalentTab = () => (
    <TalentRecommendations recommendations={talentRecommendations} />
  );

  const renderLearningTab = () => (
    <LearningRecommendations recommendations={learningRecommendations} />
  );

  return (
    <div className="min-h-screen bg-gray-100">
      <header className="bg-white shadow-sm">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex justify-between items-center py-6 md:justify-start md:space-x-10">
            <div className="flex justify-start lg:w-0 lg:flex-1">
              <span className="text-indigo-600 font-bold text-2xl">SpectrumHR.ai</span>
            </div>
            <div className="md:flex items-center justify-end md:flex-1 lg:w-0">
              <Link to="/" className="ml-8 whitespace-nowrap inline-flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-indigo-600 hover:bg-indigo-700">
                <Home size={20} className="mr-2" />
                Home
              </Link>
            </div>
          </div>
        </div>
      </header>

      <main className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">
        <div className="px-4 py-6 sm:px-0">
          <h1 className="text-3xl font-bold text-gray-900 mb-6">
            Enterprise Talent Management Dashboard
          </h1>

          <div className="bg-white overflow-hidden shadow-sm sm:rounded-lg">
            <div className="p-6 bg-white border-b border-gray-200">
              <div className="flex space-x-4 mb-6">
                <button
                  className={`px-4 py-2 rounded-md ${
                    activeTab === "overview"
                      ? "bg-indigo-600 text-white"
                      : "bg-gray-200 text-gray-700 hover:bg-gray-300"
                  }`}
                  onClick={() => setActiveTab("overview")}
                >
                  <Briefcase className="inline-block mr-2" size={16} />
                  Overview
                </button>
                <button
                  className={`px-4 py-2 rounded-md ${
                    activeTab === "talent"
                      ? "bg-indigo-600 text-white"
                      : "bg-gray-200 text-gray-700 hover:bg-gray-300"
                  }`}
                  onClick={() => setActiveTab("talent")}
                >
                  <Users className="inline-block mr-2" size={16} />
                  Talent
                </button>
                <button
                  className={`px-4 py-2 rounded-md ${
                    activeTab === "learning"
                      ? "bg-indigo-600 text-white"
                      : "bg-gray-200 text-gray-700 hover:bg-gray-300"
                  }`}
                  onClick={() => setActiveTab("learning")}
                >
                  <Zap className="inline-block mr-2" size={16} />
                  Learning
                </button>
              </div>

              {renderTabContent()}
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default Dashboard;
